<script setup lang="ts">
/**
 * @file Screen size aware drawer modal that renders OzDrawer on phone and OzPopoverModal on desktop. Used in Content picker.
 */
import OzDrawerDraggable from '@@/library/v4/components/OzDrawerDraggable.vue'
import OzDrawerStatic from '@@/library/v4/components/OzDrawerStatic.vue'
import OzPopoverModal from '@@/library/v4/components/OzPopoverModal.vue'

withDefaults(
  defineProps<{
    isPhone?: boolean
    isDraggableDrawer?: boolean
  }>(),
  {
    isPhone: false,
    isDraggableDrawer: false,
  },
)

defineEmits(['scrim-click', 'scrim-esc', 'swipedown', 'swipeup', 'trigger-anchor-update'])
</script>

<script lang="ts">
export default {}
</script>

<template>
  <OzDrawerStatic
    v-if="isPhone && !isDraggableDrawer"
    v-bind="{ ...$props, ...$attrs }"
    @scrim-click="$emit('scrim-click')"
    @scrim-esc="$emit('scrim-esc')"
  >
    <template #header><slot name="header" /></template>
    <template #body><slot name="body" /></template>
  </OzDrawerStatic>
  <OzDrawerDraggable
    v-else-if="isPhone && isDraggableDrawer"
    v-bind="{ ...$props, ...$attrs }"
    @scrim-click="$emit('scrim-click')"
    @scrim-esc="$emit('scrim-esc')"
    @swipedown="$emit('swipedown', $event)"
    @swipeup="$emit('swipeup', $event)"
  >
    <template #header><slot name="header" /></template>
    <template #body><slot name="body" /></template>
  </OzDrawerDraggable>
  <OzPopoverModal
    v-else
    v-bind="{ ...$props, ...$attrs }"
    @trigger-anchor-update="$emit('trigger-anchor-update')"
    @scrim-click="$emit('scrim-click')"
    @scrim-esc="$emit('scrim-esc')"
  >
    <template #header><slot name="header" /></template>
    <template #body><slot name="body" /></template>
  </OzPopoverModal>
</template>
